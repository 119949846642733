import React, { useState, useEffect } from 'react'
// import { isEmpty } from 'react-redux-firebase'
// import { connect } from 'react-redux'
// import { useFirebase } from 'react-redux-firebase'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useAuth, useUser } from 'reactfire'
import { signInWithEmailAndPassword } from 'firebase/auth'

// const enhance = connect(({ firebase: { auth, profile } }) => ({
//   auth,
//   profile,
// }))

function CintoiaLogo(props) {
  return (
    <div className='logo'>
      <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
      <span className='brandtext'>CINTOIA</span>
    </div>
  )
}

function Copyright(props) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://cintoia.com/'>
        Cintoia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

function SignIn() {
  const auth = useAuth()
  const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  let navigate = useNavigate()
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const errorToggle = () => setErrorModalOpen(!errorModalOpen)


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CintoiaLogo />
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={(event) => setPassword(event.target.value)}
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                 signInWithEmailAndPassword(auth, email, password)
                    .then((reset) => {
                      navigate('/')
                    })
                    .catch((error) => {
                      if (error.code === 'auth/user-disabled') {
                        setLoginErrorMessage('Tunnus disabloitu ylläpidon toimesta. Ota yhteyttä asiakaspalveluun.')
                      } else if (error.code === 'auth/wrong-password') {
                        setLoginErrorMessage('Väärä salasana')
                      } else if (error.code === 'auth/too-many-requests') {
                        setLoginErrorMessage(
                          'Liian paljon sisäänkirjautumisyrityksiä. Tunnus poistettu käytöstä turvallisuussyistä. Voit ottaa tunnuksen käyttöön nollaamalla salasanan tai kokeilemalla hetken päästä uudelleen.'
                        )
                      } else if (error.code === 'auth/invalid-email') {
                        setLoginErrorMessage('Vääränmuotoinen sähköpostiosoite')
                      } else if (error.code === 'auth/user-not-found') {
                        setLoginErrorMessage('Ei löydy käyttäjää tunnuksella ' + email)
                      } else {
                        setLoginErrorMessage('Tuntematon virhe sisäänkirjauksessa.')
                      }
                      errorToggle()
                      // console.log(error)
                    })
                }
              >
                Sign In
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
          <Dialog
            open={errorModalOpen}
            onClose={errorToggle}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Error'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>{loginErrorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={errorToggle}>Close</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default SignIn
