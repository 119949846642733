import 'firebase/database'
import { ref, query, orderByChild, update } from 'firebase/database'
import React, { useState } from 'react'
import { DatabaseProvider, useDatabase, useDatabaseListData } from 'reactfire'
import { Box, Button } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'

function DeleteToolbar({ onClicked }) {
  return (
    <Button color='primary' startIcon={<DeleteIcon />} onClick={onClicked}>
      Delete selected
    </Button>
  )
}

function CustomToolbar({ onDeleteClicked }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <DeleteToolbar onClicked={onDeleteClicked} />
    </GridToolbarContainer>
  )
}

function CustomerList({ custid }) {
  const [selectedRows, setSelectedRows] = useState([])
  const database = useDatabase()
  const phoneusersRef = ref(database, 'digiovicallin/' + custid)
  const phoneusersQuery = query(phoneusersRef, orderByChild('firstname'))
  const { status, data: phoneusers } = useDatabaseListData(phoneusersQuery, {
    idField: 'id',
  })

  const columns = [
    { field: 'firstname', headerName: 'Etunimi', width: 180, editable: true, flex: 1 },
    { field: 'lastname', headerName: 'Sukunimi', width: 180, editable: true, flex: 1 },
    { field: 'email', headerName: 'Sähköposti', width: 180, editable: true, flex: 1 },
    {
      field: 'validuntil',
      headerName: 'Voimassa asti',
      type: 'date',
      flex: 1,
      editable: true,
    },
    {
      field: 'phone',
      headerName: 'Puhelin',
      type: 'string',
      width: 220,
      editable: true,
      flex: 1,
    },
  ]

  const onCellEditCommit = (params) => {
    const key = params.id + '/' + params.field
    const updateRow = { [key]: params.value }
    update(phoneusersRef, updateRow)
  }

  const onDeleteClicked = () => {
    const deleteRows = selectedRows.reduce((acc, id) => {
      acc[id] = null
      return acc
    }, {})
    // console.log('deleteRows', deleteRows)
    update(phoneusersRef, deleteRows)
  }

  return (
    <React.Fragment>
      <DatabaseProvider sdk={database}>
        <Box sx={{ width: '100%', height: '100vh' }}>
          <DataGrid
            checkboxSelection
            columns={columns}
            rows={phoneusers || []}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 200]}
            onCellEditCommit={onCellEditCommit}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: { onDeleteClicked },
            }}
            onSelectionModelChange={setSelectedRows}
          />
        </Box>
      </DatabaseProvider>
    </React.Fragment>
  )
}

export default CustomerList
