import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CustomerManager from './CustomerManager'


function Home() {
  return (
    <CustomerManager />
  )
}

export default Home