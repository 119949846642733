import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Button, Typography, Paper } from '@mui/material'
import * as XLSX from 'xlsx'
import 'firebase/database'
import { getDatabase, ref, push, set } from 'firebase/database'
import { DatabaseProvider, useFirebaseApp, useUser, useIdTokenResult } from 'reactfire'
import DrawerMenu from './DrawerMenu'

const REQUIRED_COLUMNS = ['firstname', 'lastname', 'email', 'phone']

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

function Importer() {
  const [rowCount, setRowCount] = React.useState(0)
  const [doneImporting, setDoneImporting] = React.useState(false)
  const user = useUser()
  const { data: udata } = useIdTokenResult(user.data, false)
  let custid = ''
  if (udata && udata.claims && udata.claims.customerId) {
    custid = udata.claims.customerId
  }
  const firebaseApp = useFirebaseApp()
  const database = getDatabase(firebaseApp)
  const phoneusersRef = ref(database, 'digiovicallin/' + custid)

  const storeFiltered = (filtered) => {
    if (custid === '') {
      alert('No customer id found')
      return
    } else {
      filtered.forEach((item) => {
        const newRef = push(phoneusersRef)
        set(newRef, item)
      })
      setRowCount(filtered.length)
      setDoneImporting(true)
    }
  }

  const filter = (json) => {
    let errors = false
    const filtered = json.map((item) => {
      // console.log(item)
      // console.log(errors)
      const c = {}
      REQUIRED_COLUMNS.forEach((column) => {
        if (!item[column]) {
          errors = true
        }
        if (!item.hasOwnProperty(column)) {
          errors = true
        }
        c[column] = item[column]
      })
      return c
    })
    if (errors) {
      alert('Some required columns are missing')
    } else {
      // console.log(filtered)
      storeFiltered(filtered)
    }
  }

  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = XLSX.utils.sheet_to_json(worksheet)
        filter(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <DatabaseProvider sdk={database}>
        <DrawerMenu />
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {!doneImporting ? (
            <Button variant='contained' component='label'>
              Tuo excelistä <input hidden accept='.xlsx' type='file' onChange={readUploadFile} />
            </Button>
          ) : (
            <span>Imported {rowCount} rows</span>
          )}

          <Paper elevation={1} sx={{ m: 5, p: 3 }}>
            <Typography variant='h5' component='div'>
              Huom!
            </Typography>
            <Typography variant='body2' sx={{ mt: 3 }}>Excelissä saa olla vain yksi välilehti ja ensimmäiseltä riviltä pitää löytyä seuraavat otsikot kirjoitettuna pienellä. Järjestyksellä ei ole väliä.</Typography>
            <Typography variant='subtitle2'>firstname, lastname, email, phone</Typography>
            <Typography variant='body2'>Muuten import ei onnistu</Typography>
            <img src={process.env.PUBLIC_URL + '/excelexample.png'} alt='excel' />

          </Paper>
        </Box>
      </DatabaseProvider>
    </Box>
  )
}

export default Importer
