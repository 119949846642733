import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { getAuth } from 'firebase/auth'
import { doc, getFirestore } from 'firebase/firestore'
import { FirebaseAppProvider, FirestoreProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'

function App() {
  return (
    <BrowserRouter>
      <AppNavigator />
    </BrowserRouter>
  )
}

export default App
