import React from 'react'
// import HomeScreen from './screens/HomeScreen'
import { Routes, Route } from 'react-router-dom'
// import { connect } from 'react-redux'
// import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import Login from './screens/Login'
import PrivateRoute from './screens/PrivateRoute'
import Home from './screens/Home.js'
import Importer from './screens/Importer.js'
import { useFirebaseApp, useSigninCheck, AuthProvider } from 'reactfire'
import { getAuth } from 'firebase/auth'

// import CustomerManager from './screens/CustomerManager'
// import UserManager from './screens/UserManager'
// import CustomerImportManager from './screens/CustomerImportManager'

// const enhance = connect(
//   // Map redux state to component props
//   ({ firebase: { auth, profile } }) => ({
//     auth,
//     profile,
//   })
// )

// function PrivateRoute({ children, isLoggedIn, auth, profile, firebase, dispatch,  ...rest }) {
//   console.log('profile', profile, rest)
//   let role = 'USER'
//   if (isLoggedIn) {
//     role = profile.token.claims.role
//   }
//   const roleOk = (role === 'SUPERADMIN')
//   if (isLoggedIn && !roleOk) {
//     console.log('logging out')
//     firebase.logout().then((reset) => {
//       dispatch({
//         type: 'USER_LOGOUT',
//         payload: null,
//       })
//     })
//   }
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isLoggedIn && roleOk ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   )
// }

const AppNavigator = () => {
  // const authLoaded = isLoaded(auth)
  // const profileLoaded = isLoaded(profile)
  // const isLoggedIn = !isEmpty(auth)
  // const firebase = useFirebase()
  // if (!authLoaded || !profileLoaded)  {
  //   return <div style={{ flex: 1 }}>Wait</div>
  // } else {
    const firebaseApp = useFirebaseApp()
    const auth = getAuth(firebaseApp)



    // const { status, data: signInCheckResult } = useSigninCheck();
    // console.log(signInCheckResult)


  return (
    <AuthProvider sdk={auth}>
    {/* <FirestoreProvider sdk={firestoreDatabase}> */}
      <Routes>
        <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/import' element={<Importer/>}/>
        </Route>
        <Route exact path='/login' element={<Login />} />
        {/* <PrivateRoute path={'/manager'} auth={auth} profile={profile} firebase={firebase} dispatch={dispatch} isLoggedIn={isLoggedIn}>
          <CustomerManager />
        </PrivateRoute>
        <PrivateRoute path={'/user'} auth={auth} profile={profile} firebase={firebase}  dispatch={dispatch} isLoggedIn={isLoggedIn}>
          <UserManager />
        </PrivateRoute>
        <PrivateRoute path={'/importer'} auth={auth} profile={profile} firebase={firebase}  dispatch={dispatch} isLoggedIn={isLoggedIn}>
          <CustomerImportManager />
        </PrivateRoute>
        <PrivateRoute path={'/'} auth={auth} profile={profile} firebase={firebase}  dispatch={dispatch} isLoggedIn={isLoggedIn}>
          <HomeScreen />
        </PrivateRoute> */}
        </Routes>
      {/* </FirestoreProvider> */}
    </AuthProvider>
    )
  // }
}

export default AppNavigator
