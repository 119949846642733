import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

const PrivateRoute = () => {
  const { status, data: signInCheckResult } = useSigninCheck({
    validateCustomClaims: (userClaims) => {
      // console.log('userClaims', userClaims)
      return {
        hasRequiredClaims: userClaims.role === 'ADMIN',
      };
    },
  })

  //const { status, data: signInCheckResult } = 
  //useSigninCheck({ requiredClaims: { role: 'ADMIN' } })
  // console.log('signInCheckResult', signInCheckResult)
  const auth = signInCheckResult && signInCheckResult.signedIn === true && signInCheckResult.hasRequiredClaims === true; // determine if authorized, from context or however you're doing it
  return auth ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;