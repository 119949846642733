import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CustomerList from '../components/CustomerList'
import 'firebase/database'
import { getDatabase, ref, push, set } from 'firebase/database'
import { DatabaseProvider, useFirebaseApp, useUser, useIdTokenResult } from 'reactfire'
import { Link } from 'react-router-dom'
import DrawerMenu from './DrawerMenu'

// import JSONPretty from 'react-json-prettify'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

function CustomerManager() {
  const user = useUser()
  const { data: udata } = useIdTokenResult(user.data, false)
  let custid = ''
  if (udata && udata.claims && udata.claims.customerId) {
    custid = udata.claims.customerId
  }
  const [addOpen, setAddOpen] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [newAccount, setNewAccount] = React.useState({})
  const firebaseApp = useFirebaseApp()
  const database = getDatabase(firebaseApp)
  const phoneusersRef = ref(database, 'digiovicallin/' + custid)

  const handleClickAddOpen = () => {
    setAddOpen(true)
  }

  const handleAddClose = () => {
    setNewAccount({})
    setAddOpen(false)
  }

  const handleErrorClose = () => {
    setErrorOpen(false)
  }

  const handleAddSave = () => {
    const newRef = push(phoneusersRef)
    set(newRef, newAccount)
    handleAddClose()
  }

  const onChangeAddForm = (event) => {
    setNewAccount({ ...newAccount, [event.target.name]: event.target.value })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <DatabaseProvider sdk={database}>
        <DrawerMenu />
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Button variant='contained' startIcon={<PersonAddIcon />} onClick={handleClickAddOpen}>
            Lisää asiakas
          </Button>{' '}
          <Button component={Link} to={'/import'} variant='contained' startIcon={<GroupAddIcon />}>
            Tuo Excelistä
          </Button>

          <CustomerList custid={custid}/>

          <Dialog open={addOpen} onClose={handleAddClose}>
            <DialogTitle>Uusi asiakas</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin='dense'
                id='email'
                label='Sähköposti'
                type='email'
                name='email'
                fullWidth
                variant='standard'
                onChange={onChangeAddForm}
              />
              <TextField
                margin='dense'
                id='firstname'
                label='Etunimi'
                type='text'
                fullWidth
                name='firstname'
                variant='standard'
                onChange={onChangeAddForm}
              />
              <TextField
                margin='dense'
                id='lastname'
                label='Sukunimi'
                type='text'
                fullWidth
                name='lastname'
                variant='standard'
                onChange={onChangeAddForm}
              />
              <TextField
                margin='dense'
                id='phone'
                label='Puhelin (esim. +358501234567 tai 0501234567)'
                name='phone'
                type='text'
                fullWidth
                variant='standard'
                onChange={onChangeAddForm}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddClose}>Peruuta</Button>
              <Button onClick={handleAddSave}>Tallenna</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={errorOpen} onClose={handleErrorClose}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>{/* <JSONPretty json={errorMessage} /> */}</DialogContent>
            <DialogActions>
              <Button onClick={handleErrorClose}>Sulje</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </DatabaseProvider>
    </Box>
  )
}

export default CustomerManager
